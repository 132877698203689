import { observer } from "mobx-react-lite";
import { ReactNode } from "react";

import {
  CardAbstractCollectable,
  CardAbstractCollectableDriver,
} from "./CardAbstractCollectable";
import {
  CardItemPromotionBottom,
  CardItemPromotionBottomDriver,
} from "./CardItemPromotionBottom";
import {
  CardItemPromotionContextMenu,
  CardItemPromotionContextMenuDriver,
} from "./CardItemPromotionContextMenu";

export type CardItemPromotionDriver = CardAbstractCollectableDriver &
  CardItemPromotionBottomDriver & {
    contextMenuDriver: CardItemPromotionContextMenuDriver;
  };

interface IProps {
  driver: CardItemPromotionDriver;
  renderSharing(): ReactNode;
  noAnimationCounter?: boolean;
}

export const CardItemPromotion = observer(function CardItemPromotion(
  props: IProps,
) {
  return (
    <CardAbstractCollectable
      driver={props.driver}
      renderBottom={() => (
        <CardItemPromotionBottom
          driver={props.driver}
          noAnimationCounter={props.noAnimationCounter}
        />
      )}
      renderContextMenu={() =>
        props.driver.shouldRenderContextMenu && (
          <CardItemPromotionContextMenu
            driver={props.driver.contextMenuDriver}
          />
        )
      }
      renderSharing={props.renderSharing}
    />
  );
});
