import { observer } from "mobx-react-lite";

import { CardSize, CardType } from "../../shared/models/Card";
import { useTranslation } from "../../shared/translate/NextI18next";
import { ContextMenuCardDelete } from "../CardSimpleContext/ContextMenuCardDelete";
import ContextMenuCardEdit from "../CardSimpleContext/ContextMenuCardEdit";
import { ContextMenuCardOrder } from "../CardSimpleContext/ContextMenuCardOrder";
import { ContextMenuCardPublish } from "../CardSimpleContext/ContextMenuCardPublish";
import { ContextMenuCardSave } from "../CardSimpleContext/ContextMenuCardSave";
import { ContextMenuCardShare } from "../CardSimpleContext/ContextMenuCardShare";
import { sc } from "./CardItemPromotionContextMenu.styled";
import { ContextMenuCardResize } from "./ContextMenuCardResize";

export type CardItemPromotionContextMenuDriver = {
  contentType: CardType.PROMOTION | CardType.PROMOTION_REPOST;
  isOwnerView: boolean;
  shouldRenderResizeMenu: boolean;
  cardSize: CardSize;
  isPinHeaderButtonDisabled: boolean;
  onCardSizeChanged(size: CardSize): void;
  shouldRenderCardOrder: boolean;
  cardOrderInput: string | null;
  sortNumberInputError: string | null;
  onCardOrderChanged(order: string): void;
  onCardOrderBlured(): void;
  isEditCardButtonDisabled: boolean;
  onEditCardButtonClicked(): void;
  shouldRenderSharePromotionButton: boolean;
  onShareCardButtonClicked(): void;
  onSaveCardButtonClicked(): void;
  isDeleteCardButtonDisabled: boolean;
  shouldRenderSaveCardButton: boolean;
  shouldRenderSetDefaultCheckbox: boolean;
  isSetDefaultCheckboxChecked: boolean;
  onSetDefaultCheckboxChanged(): void;
  isSetDefaultCheckboxDisabled: boolean;
  isSaveCardButtonDisabled: boolean;
  shouldRenderPublishCardButton: boolean;
  isPublishBtnEnable: boolean;
  onDeleteCardButtonClicked(): void;
  onPublishCardButtonClicked(): void;
  hoverItem?: "DELETE" | "CARD_SIZE_HEADER" | "EDIT";
};

interface IProps {
  driver: CardItemPromotionContextMenuDriver;
}

export const CardItemPromotionContextMenu = observer(
  function CardItemPromotionContextMenu(props: IProps) {
    const {
      isOwnerView,
      cardSize,
      isPinHeaderButtonDisabled,
      onCardSizeChanged,
      shouldRenderResizeMenu,
      shouldRenderCardOrder,
      cardOrderInput,
      sortNumberInputError,
      onCardOrderChanged,
      onCardOrderBlured,
      isEditCardButtonDisabled,
      onEditCardButtonClicked,
      isDeleteCardButtonDisabled,
      shouldRenderSaveCardButton,
      shouldRenderPublishCardButton,
      isSetDefaultCheckboxChecked,
      onSetDefaultCheckboxChanged: onSetDefaultCheckboxClicked,
      isSetDefaultCheckboxDisabled,
      shouldRenderSetDefaultCheckbox,
      isSaveCardButtonDisabled,
      isPublishBtnEnable,
      shouldRenderSharePromotionButton,
      onShareCardButtonClicked,
      onDeleteCardButtonClicked,
      onSaveCardButtonClicked,
      onPublishCardButtonClicked,
      hoverItem,
    } = props.driver;
    const { t } = useTranslation("ContextMenu");

    return isOwnerView ? (
      <sc.Container>
        {shouldRenderResizeMenu && (
          <ContextMenuCardResize
            cardSize={cardSize}
            isPinHeaderButtonDisabled={isPinHeaderButtonDisabled}
            onCardSizeChanged={onCardSizeChanged}
            isHoverHeader={hoverItem === "CARD_SIZE_HEADER"}
          />
        )}
        {shouldRenderCardOrder && (
          <sc.MenuItem
            data-cy="CardItemPromotionContextMenu-MenuItemCardOrder"
            useDefaultCursor={true}
          >
            <ContextMenuCardOrder
              sortNumber={cardOrderInput || ""}
              onChanged={onCardOrderChanged}
              onBlured={onCardOrderBlured}
              sortNumberInputError={sortNumberInputError}
            />
          </sc.MenuItem>
        )}
        <sc.MenuItem>
          <ContextMenuCardEdit
            onEdit={onEditCardButtonClicked}
            disabled={isEditCardButtonDisabled}
            isHovering={hoverItem === "EDIT"}
          />
        </sc.MenuItem>
        {shouldRenderSaveCardButton && (
          <sc.MenuItem>
            <ContextMenuCardSave onSave={onSaveCardButtonClicked} />
          </sc.MenuItem>
        )}
        {shouldRenderPublishCardButton && (
          <sc.MenuItem>
            <ContextMenuCardPublish
              onPublish={onPublishCardButtonClicked}
              isValid={isPublishBtnEnable}
            />
          </sc.MenuItem>
        )}
        {shouldRenderSetDefaultCheckbox && (
          <sc.MenuItem>
            <sc.CheckBoxContainer disabled={isSetDefaultCheckboxDisabled}>
              <sc.CheckBox
                type="checkbox"
                role="checkbox"
                aria-label="CardItemPromotionContextMenu-Checkbox"
                checked={isSetDefaultCheckboxChecked}
                onChange={onSetDefaultCheckboxClicked}
                disabled={isSetDefaultCheckboxDisabled}
              />
              <sc.CheckboxDes onClick={onSetDefaultCheckboxClicked}>
                {t("context-menu.card-menu.buttons.set-default-checkbox")}
              </sc.CheckboxDes>
            </sc.CheckBoxContainer>
          </sc.MenuItem>
        )}
        {shouldRenderSharePromotionButton && (
          <sc.MenuItem>
            <ContextMenuCardShare onShare={onShareCardButtonClicked} />
          </sc.MenuItem>
        )}
        <sc.MenuItem>
          <ContextMenuCardDelete
            onDelete={onDeleteCardButtonClicked}
            disabled={isDeleteCardButtonDisabled}
            isHovering={hoverItem === "DELETE"}
          />
        </sc.MenuItem>
      </sc.Container>
    ) : (
      <sc.Container>
        {shouldRenderSaveCardButton && (
          <sc.MenuItem>
            <ContextMenuCardSave
              onSave={onSaveCardButtonClicked}
              disabled={isSaveCardButtonDisabled}
            />
          </sc.MenuItem>
        )}
        {shouldRenderSharePromotionButton && (
          <sc.MenuItem>
            <ContextMenuCardShare onShare={onShareCardButtonClicked} />
          </sc.MenuItem>
        )}
      </sc.Container>
    );
  },
);
